import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";

import IconRuble from "../../assets/icons/ruble.svg";
import IconModel from "../../assets/icons/model.svg";

import formatPrice from "../../features/Helpers/formatPrice";


const StyledCard = styled.div`
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
              0 4px 6px -2px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  position: relative;

  :hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .image {
    flex: 0 0 260px;
  }
  .title {
    position: absolute;
    top: 260px;
    left: 0;
    transform: translateY(-100%);
    background-color: #C2E9FF;
    border-radius: 0 8px 0 0;
    padding: 5px 10px;
  }
  .desc {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 1rem;
    padding: 2.5rem 1rem;

    .item {
      display: grid;
      grid-template-columns: 2.5rem auto;
      grid-column-gap: 1rem;
      align-items: center;
    }
  }
`;

const CategoryPartsCard = ({name, model, price, url, image}) => {
  // Зашгружаем картинку-заглушку из файловой системы
  const data = useStaticQuery(graphql`
  query DEFAULT_CATEGORY_PARTS_ITEM_IMAGE {
    file(name: {eq: "noimage"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`);

  return (
    <Link to={ `/parts/${url}` } title={ `${name} ${model}` }>
      <StyledCard>
      <Img className="image" fluid={ image ? image.fluid : data.file.childImageSharp.fluid } />
      <div className="title">{name}</div>
      <div className="desc">
          <div className="item">
          <IconModel className="item__icon" width="24" height="24" fill="#607A97" />
          <span className="item__desc">Модель</span>
          </div>
          <div className="value model">{ model }</div>
          <div className="item">
          <IconRuble className="item__icon" width="24" height="24" fill="#607A97" />
          <span className="item__desc">Цена</span>
          </div>
          <div className="value price">от { formatPrice(price) }</div>
      </div>
      </StyledCard>
    </Link>
  );
};

export default CategoryPartsCard;