import React from 'react';
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "styled-components";

import CategoryParts from "../components/Category/CategoryParts";
import CategoryArticles from "../components/Category/CategoryArticles";

import CategoryPartsItemsFactory from "../features/Category/CategoryPartsItemsFactory";
import BreadcrumbsFactory from "../features/Layout/Breadcrumbs/BreadcrumbsFactory";
const breadcrumbsFactory = new BreadcrumbsFactory();


const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: ${ props => props.hasArticles ? `1fr 20%` : `1fr` };
  grid-column-gap: 3rem;
  align-items: center;
  padding-bottom: 8rem;

  .articles {
    height: 100%;
    border-left: 1px solid #eee;
    grid-column: 2 / 3;
    grid-row: 1 / span 3;
  }
  .parts {
    grid-column: 1 / 2;
  }
`;

const Category = ({ pageContext, data }) => {
  const { title, description, slug, shortName } = pageContext;

  const rawParts = CategoryPartsItemsFactory.fromArray(data.items.nodes, data.config.models);
  const parts = CategoryPartsItemsFactory.addImages(rawParts, data.images.nodes);

  const articles = data.articles.nodes;
  const hasArticles = Boolean(articles.length);

  // Создаем объекты для хлебных крошек
  const crumbs = breadcrumbsFactory.fromArray([
    {
      path: 'categories',
      title: 'Категории',
    },
    {
      path: slug,
      title: shortName,
    },
  ]);

  return (
    <Layout crumbs={ crumbs }>
      <SEO title={ title } />
      <StyledContainer className="container" hasArticles={ hasArticles }>
        { hasArticles && <CategoryArticles articles={ articles } /> }
        <h1>{ title }</h1>
        <article className="description" dangerouslySetInnerHTML={ {__html: description}}></article>
        <CategoryParts parts={ parts } title={ shortName } />
      </StyledContainer>
    </Layout>
  );
};

export const query = graphql`
  query CATEGORY_ARTICLES_AND_PARTS_QUERY($shortName: String!) {
    items:allPricelistXlsxCatalog(limit: 9, filter: {category: {eq: $shortName}}) {
      nodes {
        name
        model
        price
        code
      }
    }
    config:configYaml(type: {eq: "models"}) {
      models {
        title
        slug
      }
    }
    articles:allMarkdownRemark(filter: {
        fileAbsolutePath: {regex: "/articles/"},
        frontmatter: {categories: {eq: $shortName}}
      }) {
      nodes {
        frontmatter {
          title
          slug
          sort
        }
      }
    }
    images:allFile(filter: {dir: {regex: "/cdn/parts/"}}) {
      nodes {
        name
        childImageSharp {
          fluid(maxHeight: 260) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;

export default Category;