import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const StyledContainer = styled.div`
  padding: 2rem 1rem;

  h4 {
    margin-top: 0;
    margin-bottom: 1rem;
    text-transform: uppercase;
    text-align: center;
  }
`;

const StyledList = styled.ul`
`;

const CategoryArticles = ({articles}) => {

  return (
    <StyledContainer className="articles">
      <h4>Статьи на тему</h4>
      <StyledList>
        {articles.map(article => <li key={article.frontmatter.slug}>
          <Link to={`/articles/${article.frontmatter.slug}`}>{article.frontmatter.title}</Link>
      </li>)}
      </StyledList>
    </StyledContainer>
  );
}

export default CategoryArticles;