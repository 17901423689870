import CategoryPartsItem from "./CategoryPartsItemDTO";
import SlugFactory from "../Parts/SlugFactory";

export default class CategoryPartsItemsFactory {
  static fromArray(categoryPartsItemsArray, modelsArray) {
    const categoryPartsItems = [];
    const slugFactory = new SlugFactory(modelsArray);
    categoryPartsItemsArray.forEach(categoryPartsItemsObject => {
      categoryPartsItemsObject.slug = slugFactory.create(categoryPartsItemsObject);
      const categoryPartsItemDTO = new CategoryPartsItem(categoryPartsItemsObject);
      categoryPartsItems.push(categoryPartsItemDTO);
    });
    return categoryPartsItems;
  }

  static addImages(categoryPartsItems, imagesArray) {
    console.log(categoryPartsItems, imagesArray);
    categoryPartsItems.map(item => {
      const image = imagesArray.find(image => image.name === String(item.code));
      if (image) {
        item.image = image.childImageSharp;
      }
      return item;
    });
    return categoryPartsItems;
  }
}