export default class CategoryPartsItem {

  constructor(partsItemDataObject) {
    this.code = partsItemDataObject.code;
    this.name = partsItemDataObject.name;
    this.price = partsItemDataObject.price;
    this.url = partsItemDataObject.slug;
    this.model = partsItemDataObject.model;
    this.image = undefined;
  }
}